<template>
  <div class="h-screen-ios min-h-screen-ios">
    <header
      class="bg-surface-base h-20 lg:h-[90px] grid items-center fixed left-0 right-0 lg:border-b lg:border-surface-60"
    >
      <h1 class="flex justify-center overflow-hidden">
        <Img
          :src="`${cdn}/heltia-logo.webp`"
          alt="Heltia Logo"
          width="84"
          @click="$router.push('/')"
          class="cursor-pointer"
        />
      </h1>
    </header>
    <main id="app" class="min-h-screen w-screen bg-surface-base">
      <div class="pt-20">
        <slot />
      </div>
    </main>
  </div>
</template>

<script setup>
import { computed } from "vue";

const route = useRoute();
const config = useRuntimeConfig();
const cdn = config.public.cdn;

const canonicalLink = computed(() => {
  const baseUrl =
    config.public.env === "production"
      ? "https://www.getheltia.com"
      : "https://www.staging.getheltia.com";

  const url = new URL(`${baseUrl}${route.path}`);
  if (route.query["page"] === "1") {
    delete route.query["page"];
  }
  url.search = new URLSearchParams(route.query).toString();
  return url.toString();
});

useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: canonicalLink.value,
    },
  ],
}));
</script>
